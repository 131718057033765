import React, { useState } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

import EmergencyBreakdown from "../../images/EmergencyBreakdown.jpg";
import ColdStorage from "../../images/ColdStorage.jpg";
import Amonia from "../../images/Amonia.png";
import OurPhilosophy from "../../images/OurPhilosophy.jpg";
import safety_img from "../../images/safety_img.webp";
import coldstore from "../../images/coldstore.webp";
import comming_soon_img from "../../images/comming_soon_img.jpg";
import confectionary from "../../images/confectionary.webp";
import DISTribution from "../../images/DISTribution.webp";
import Dairy from "../../images/Dairy.webp";
import Beverage from "../../images/Beverage.webp";
import Brewery_Industry from "../../images/Brewery_Industry.webp";
import Cold_Storage from "../../images/Cold_Storage.webp";
import mine_cooling from "../../images/mine_cooling.webp";
import Meat_Processing from "../../images/Meat_Processing.webp";
import enviropack from "../../images/enviropack.webp";
import service_min from "../../images/service_min.webp";
import ammonia_img from "../../images/ammonia_img.webp";




function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "white", borderRadius: "100px" }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "white", borderRadius: "100px" }}
            onClick={onClick}
        />
    );
}


function OurWorldwidePartnersNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "white", borderRadius: "100px" }}
            onClick={onClick}
        />
    );
}

function OurWorldwidePartnersPrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "white", borderRadius: "100px" }}
            onClick={onClick}
        />
    );
}


const Home = () => {
    const bannerSlider = {
        dots: true,
        infinite: true,
        fade: true,
        autoplay: true,
        speed: 500,
        autoplaySpeed: 2500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]

    };

    const [homeBannnerSlider, setHomeBannnerSlider] = useState([
        { id: 1, photo: ColdStorage, title: "GORDON BROTHERS INDUSTRIES", description: "HUGE RANGE OF INDUSTRIAL REFRIGERATION SPARE PARTS & LUBRICANTS" },
        { id: 2, photo: EmergencyBreakdown, title: "GORDON BROTHERS INDUSTRIES", description: "HUGE RANGE OF INDUSTRIAL REFRIGERATION SPARE PARTS & LUBRICANTS" },
        { id: 3, photo: Amonia, title: "GORDON BROTHERS INDUSTRIES", description: "HUGE RANGE OF INDUSTRIAL REFRIGERATION SPARE PARTS & LUBRICANTS" },
        { id: 4, photo: OurPhilosophy, title: "GORDON BROTHERS INDUSTRIES", description: "HUGE RANGE OF INDUSTRIAL REFRIGERATION SPARE PARTS & LUBRICANTS" },

    ]);

    const ourWorldwidePartnersSlider = {
        className: "center",
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 8,
        slidesToScroll: 8,
        initialSlide: 0,
        nextArrow: <OurWorldwidePartnersNextArrow />,
        prevArrow: <OurWorldwidePartnersPrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ]

    };
    const [ourWorldwidePartners, setOurWorldwidePartners] = useState([
        {
            images: comming_soon_img, id: 1
        },
        {
            images: comming_soon_img, id: 2
        },
        {
            images: comming_soon_img, id: 3
        },
        {
            images: comming_soon_img, id: 4
        },
        {
            images: comming_soon_img, id: 5
        },
        {
            images: comming_soon_img, id: 6
        },
        {
            images: comming_soon_img, id: 7
        },
        {
            images: comming_soon_img, id: 8
        },
        {
            images: comming_soon_img, id: 9
        },
        {
            images: comming_soon_img, id: 10
        },

    ]);

    const [keyIndustries, setKeyIndustries] = useState([
        {
            image: Meat_Processing, id: 1,
            title_name: "Meat Processing", card_link: "http://www.bangabhaban.gov.bd/",
            desc: "Combine nature, culture, and heritage on this five day tour of Bangladesh. Guides will lead you on this adventure as you deepen your appreciation of Bangladeshi traditions. Start in the city of Dhaka to appreciate the nation's monuments to political life, such as the national museum and the local university. Tour highlights include Srimangal - the tea capital of Bangladesh, and Lawachara National Park, where you'll explore tropical rainforests.",

        },
        {
            image: mine_cooling, id: 2,
            title_name: "Mine Cooling", card_link: "http://www.pmo.gov.bd/",
            desc: "Combine nature, culture, and heritage on this five day tour of Bangladesh. Guides will lead you on this adventure as you deepen your appreciation of Bangladeshi traditions. Start in the city of Dhaka to appreciate the nation's monuments to political life, such as the national museum and the local university. Tour highlights include Srimangal - the tea capital of Bangladesh, and Lawachara National Park, where you'll explore tropical rainforests.",

        },
        {
            image: Cold_Storage, id: 3,
            title_name: "Cold Storage", card_link: "http://www.cabinet.gov.bd/",
            desc: "Combine nature, culture, and heritage on this five day tour of Bangladesh. Guides will lead you on this adventure as you deepen your appreciation of Bangladeshi traditions. Start in the city of Dhaka to appreciate the nation's monuments to political life, such as the national museum and the local university. Tour highlights include Srimangal - the tea capital of Bangladesh, and Lawachara National Park, where you'll explore tropical rainforests.",

        },
        {
            image: Brewery_Industry, id: 4,
            title_name: "Brewery", card_link: "http://www.bhcdelhi.org/govt.htm",
            desc: "Combine nature, culture, and heritage on this five day tour of Bangladesh. Guides will lead you on this adventure as you deepen your appreciation of Bangladeshi traditions. Start in the city of Dhaka to appreciate the nation's monuments to political life, such as the national museum and the local university. Tour highlights include Srimangal - the tea capital of Bangladesh, and Lawachara National Park, where you'll explore tropical rainforests.",

        },
        {
            image: Beverage, id: 5,
            title_name: "Beverage", card_link: "http://www.bhcdelhi.org/govt.htm",
            desc: "Combine nature, culture, and heritage on this five day tour of Bangladesh. Guides will lead you on this adventure as you deepen your appreciation of Bangladeshi traditions. Start in the city of Dhaka to appreciate the nation's monuments to political life, such as the national museum and the local university. Tour highlights include Srimangal - the tea capital of Bangladesh, and Lawachara National Park, where you'll explore tropical rainforests.",

        },
        {
            image: Dairy, id: 5,
            title_name: "Dairy", card_link: "http://www.bhcdelhi.org/govt.htm",
            desc: "Combine nature, culture, and heritage on this five day tour of Bangladesh. Guides will lead you on this adventure as you deepen your appreciation of Bangladeshi traditions. Start in the city of Dhaka to appreciate the nation's monuments to political life, such as the national museum and the local university. Tour highlights include Srimangal - the tea capital of Bangladesh, and Lawachara National Park, where you'll explore tropical rainforests.",

        },
        {
            image: DISTribution, id: 5,
            title_name: "Distribution Centres", card_link: "http://www.bhcdelhi.org/govt.htm",
            desc: "Combine nature, culture, and heritage on this five day tour of Bangladesh. Guides will lead you on this adventure as you deepen your appreciation of Bangladeshi traditions. Start in the city of Dhaka to appreciate the nation's monuments to political life, such as the national museum and the local university. Tour highlights include Srimangal - the tea capital of Bangladesh, and Lawachara National Park, where you'll explore tropical rainforests.",

        },
        {
            image: confectionary, id: 5,
            title_name: "Confectionery", card_link: "http://www.bhcdelhi.org/govt.htm",
            desc: "Combine nature, culture, and heritage on this five day tour of Bangladesh. Guides will lead you on this adventure as you deepen your appreciation of Bangladeshi traditions. Start in the city of Dhaka to appreciate the nation's monuments to political life, such as the national museum and the local university. Tour highlights include Srimangal - the tea capital of Bangladesh, and Lawachara National Park, where you'll explore tropical rainforests.",

        },
    ])


    return (
        <>
            <div className="margin__top slick__slider">
                <Slider {...bannerSlider}>
                    {
                        homeBannnerSlider.length > 0 && homeBannnerSlider.map((item, id) => {
                            return (
                                <div key={id} className="home__banner">
                                    <div className="banner__img">
                                        <img src={item.photo} alt="image" loading="lazy" />
                                    </div>
                                    <div className="banner__text">
                                        <h2 className="banner__title">{item.title}</h2>
                                        <div className="banner__desc__sec">
                                            <p className="banner__description">
                                                {item.description}
                                            </p>
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <button type="text" className="banner__btn">Learn More</button>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </Slider>
            </div>

            <div className="container margin__top">
                <div className="row g-3">
                    <div className="col-md-6">
                        <h6 className="text__subheader"><span className=""> Leaders In Industrial Refrigeration</span></h6>
                        <h3 className="txt__ars__header text__ars__color">Australian Refrigeration Services</h3>
                        <p className="text__description">
                            Australian Refrigeration Services (ARS) is an industrial and commercial refrigeration
                            company with a difference. We pride ourselves on our understanding of the pressures of
                            business today, hence work closely with our clients to deliver cost effective, value-based solutions.
                            Our focus is on delivering engineering refrigeration-based solutions, ranging from simple
                            cost-effective plants, right through to highly specialised unique applications. We also provide
                            commercially responsible refrigeration design consultation, engineering performance reviews,
                            after-sales service, as well as emergency breakdown response – 24-hours a day, 7 days a week.
                            Our client base is diverse, which highlights our unique skill to be able to adapt to any industry type.
                        </p>
                        <button type="text" className="btn__button">About Us</button>
                    </div>
                    <div className="col-md-6 ps-md-5">
                        <div className="ars__img">
                            <img src={coldstore} alt="" className="img-fluid" loading="lazy" />
                        </div>
                    </div>
                </div>

                <div className="row g-3 pt-5 pb-5">
                    <h3 className="txt__ars__header text__ars__color text-center">Some of Our Clients</h3>
                    <div className="col-md-12">
                        <div className="world__partner__carousel pt-3 ">
                            <Slider {...ourWorldwidePartnersSlider}>
                                {
                                    ourWorldwidePartners.length > 0 && ourWorldwidePartners.map((item, id) => {
                                        return (
                                            <div key={id} className="web__carousel__box__card">
                                                <div className="card text-center">
                                                    <div className="pt-0">
                                                        <img src={item.images} className="img-fluid" style={{ width: "100%", height: "117px", padding: "25px" }} />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </Slider>
                        </div>
                    </div>
                </div>

                <div className="row g-3 pt-5 margin__top d-flex justify-content-center">
                    <h3 className="txt__ars__header text__ars__color text-center">key industries</h3>
                    <div className="col-md-4">
                        <p className="text__description">Gordon Brothers has the extensive engineering knowledge and experience to provide industrial refrigertion solutions across a wide range of industries.</p>
                    </div>
                </div>
                <div className="row g-3 gx-md-5 pt-2 d-flex justify-content-center">
                    {
                        keyIndustries.length > 0 && keyIndustries.map((item, id) => {
                            return (
                                <div key={id} className="col-md-3">
                                    <div className="key__industries__card">
                                        <div className="key__industries__card__img">
                                            <img src={item.image} className="card-img-top" alt="image" loading="lazy" />
                                        </div>
                                        <div className="card-body p-2 pt-3">
                                            <h6 className="key__industries__card__title">{item.title_name}</h6>
                                            <p className="key__industries__card__discription">{item.designation}</p>
                                        </div>
                                        <div className="key__industries__card__overlay" type="button">
                                            <Link to={`industries/${id}`} style={{ textDecoration: "none" }}>
                                                View Details <br />Click Now
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }

                    <div className="row pt-5">
                        <div className="col-md-12">
                            <div className="btn__accom__more text-center">
                                <button type="text" className="btn__button">Load More</button>
                            </div>
                        </div>
                    </div>
                </div >
            </div>


            <div className="services__dept pt-5">
                <div className="services__dept__img">
                    <img src={service_min} alt="image" className="img-fluid" loading='lazy' />
                </div>
                <div className="services__dept__overlay">
                    <div className="card">
                        <div className="services__dept__card_img">
                            <img src={ammonia_img} className="card-img-top" alt="image" loading='lazy' />
                        </div>
                        <div Name="card-body">
                            <h5 className="services__txt__subhd px-3">AMMONIA REFRIGERATION SYSTEMS</h5>
                            <h3 className="services__txt__hd px-3">24/7 Services Deprtment</h3>
                            <p className="text__description px-3">We have all the experience to service the refrigeration needs for a wide variety of industries,
                                including red meat, poultry, pork, smallgoods, seafood, frozen vegetables, pet food, dairy,
                                margarine, ice cream, fruit processing, brewery, mining and more! Our technicians have extensive
                                knowledge in ammonia refrigeration systems to ensure your systems are reliable and as efficient
                                as possible.
                            </p>
                            <div className="d-flex justify-content-center pt-2">
                                <button type="text" className="btn__button">Learn More</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


            <div className="container margin__top">
                <div className="row g-3">
                    <div className="col-md-6">
                        {/* <h3 className="txt__ars__header" style={{color: "#B2D334"}}> */}
                        <h3 className="txt__ars__header text__ars__color">
                            <span>Engineering&nbsp;</span>
                            <br />
                            <span>Custom Solutions</span>
                        </h3>
                        <p className="text__description">
                            Gordon Brothers industries is Australia’s largest and most experienced industrial refrigeration company.
                            We are leaders in the field of natural and synthetic refrigeration solutions, specialising in low-charge
                            ammonia systems. We provide ammonia refrigeration system design, construction, servicing and parts to a wide
                            range of customers throughout Australia and overseas.
                        </p>
                        <p className="text__description">
                            We tailor our services carefully to meet our clients’ needs. Our team is dedicated to improving the reliability
                            of their refrigeration systems. We provide continual support with 24 hour; 7-day service wherever it is needed.
                            With service centres in Melbourne, Bendigo, Sydney, Griffith, Brisbane, Townsville, Adelaide, Riverland and Perth,
                            we are the only nationwide industrial refrigeration service provider in Australia.
                        </p>
                        <p className="text__description">
                            Our contracting division provides a total design and construct industrial refrigeration and cooling solution.
                            Gordon Brothers Industries designs, manufactures. installs and commissions industrial refrigeration systems for
                            a broad range of applications and industries across Australia and overseas
                        </p>
                        <button type="text" className="btn__button">Learn More</button>
                    </div>
                    <div className="col-md-6 ps-md-5">
                        <div className="esm__img">
                            <img src={enviropack} alt="image" className="img-fluid" loading="lazy" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="safety pt-5">
                <div className="safety__img">
                    <img src={safety_img} alt="image" className="img-fluid" loading='lazy' />
                </div>
                <div className="safety__text">
                    <h3 className="txt__safety__header text__safety__color">
                        We offer a range of different ammonia safety training programs that
                        ensure your staff are as safe and informed as possible.
                    </h3>
                    <div className="d-flex justify-content-center pt-2">
                        <button type="text" className="btn__button">SAFETY PROGRAMS</button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Home;