import React from 'react';

const Industries = () => {
    return (
        <div>
            Industries
        </div>
    );
};

export default Industries;