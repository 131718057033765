import React from 'react';

const IndustriesDetials = () => {
    return (
        <div className="margin__top">
            <h1 className="text-center pt-5 mb-5">
                Industries Detials Up Coming Features
            </h1>
        </div>
    );
};

export default IndustriesDetials;