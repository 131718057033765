import React from 'react';

const NotFound = () => {
    return (
        <div className="margin__top">
            <h1 className="text-center text-muted mt-5">Page Not Found 404</h1>
        </div>
    );
};

export default NotFound;