import React from 'react';
import { Link } from 'react-router-dom';
import "./Footer.css";
import ARS_logo from '../../images/ARS_logo.png';
import Indigenousft from '../../images/Indigenousft.webp';

const Footer = () => {
    return (
        <div className="ft__bg pt-4 pb-4">
            <div className="container">
                <div className="mt-4">
                    <div className="row g-3">
                        <div className="col-md-2">
                            <h6 className="ft__hd"><span>Company</span></h6>
                            <ul className="list-unstyled ft">
                                <li>
                                    <Link to="/#" className="ft__text">ABOUT US</Link>
                                </li>
                                <li>
                                    <Link to="/#" className="ft__text">100 YEAR HISTORY</Link>
                                </li>
                                <li>
                                    <Link to="/#" className="ft__text">INSIGHTS</Link>
                                </li>
                                <li>
                                    <Link to="/#" className="ft__text">CAREERS</Link>
                                </li>
                                <li>
                                    <Link to="/#" className="ft__text">CHARITABLE FOUNDATION</Link>
                                </li>
                                <li>
                                    <Link to="/#" className="ft__text">CONTACT US</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-3">
                            <h6 className="ft__hd"><span>Sevices</span></h6>
                            <ul className="list-unstyled ft">
                                <li>
                                    <Link to="/#" className="ft__text">ENGINEERING</Link>
                                </li>
                                <li>
                                    <Link to="/#" className="ft__text">24/7 SERVICE DEPARTMENT</Link>
                                </li>
                                <li>
                                    <Link to="/#" className="ft__text">AMMONIA SAFETY TRAINING</Link>
                                </li>
                                <li>
                                    <Link to="/#" className="ft__text">OUR INDUSTRIES</Link>
                                </li>
                                <li>
                                    <Link to="/#" className="ft__text">LOW-CHARGE AMMONIA</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-2">
                            <h6 className="ft__hd"><span>Products</span></h6>
                            <ul className="list-unstyled ft">
                                <li>
                                    <Link to="/#" className="ft__text">OUR PARTS RANGE</Link>
                                </li>
                                <li>
                                    <Link to="/#" className="ft__text">WXR CONDENSERS</Link>
                                </li>
                                <li>
                                    <Link to="/#" className="ft__text">REFRIGERATION LUBRICANTS</Link>
                                </li>
                                <li>
                                    <Link to="/#" className="ft__text">ENVIROPACKS</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-3">
                            <h6 className="ft__hd"><span>Policies</span></h6>
                            <ul className="list-unstyled ft">
                                <li>
                                    <Link to="/#" className="ft__text">PRIVACY POLICY</Link>
                                </li>
                                <li>
                                    <Link to="/#" className="ft__text">CONDITIONS OF SALE</Link>
                                </li>
                                <li>
                                    <Link to="/#" className="ft__text">CONDITIONS OF PURCHASE</Link>
                                </li>
                                <li>
                                    <Link to="/#" className="ft__text">QUALITY POLICY</Link>
                                </li>
                                <li>
                                    <Link to="/#" className="ft__text">MODERN SLAVERY STATEMENT</Link>
                                </li>
                                <li>
                                    <Link to="/#" className="ft__text">BUREAU VERITAS - ISO 9001:2015</Link>
                                </li>
                                <li>
                                    <Link to="/#" className="ft__text">ARCTICK AUTHORISATION NO: AU30233</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-2">
                            <div className="ft__logo">
                                <img src={ARS_logo} alt="logo" loading="lazy" />
                            </div>
                            <h5 className="ft__follow">Follow Us</h5>
                            <div className="d-flex justify-content-end">
                                <div className="ht-social">
                                    <a href="#" className="facebook"><i className="fab fa-facebook-f"></i></a>
                                    <a href="#" className="twitter"><i className="fab fa-twitter"></i></a>
                                    <a href="#" className="linkedin"><i className="fab fa-linkedin-in"></i></a>
                                    {/* <a href="#" className="whatsapp"><i className="fab fa-whatsapp"></i></a> */}
                                    <a href="#" className="instagram"><i className="fab fa-instagram"></i></a>
                                    {/* <a href="#" className="pinterest"><i className="fab fa-pinterest-p"></i></a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row g-3">
                        <div className="col-md-6">
                            <h6 className="ftb__hd">
                                Gordon Brothers Industries acknowledges the traditional owners of country
                                throughout Australia and recognises their continuing connection to land, waters and
                                community. We pay our respect to them and their cultures; and to elders past, present
                                and emerging.
                            </h6>
                            <div className="ftb__img">
                                <img src={Indigenousft} alt="image" loading="lazy" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="ft__margin__top">
                                <ul className="list-unstyled ftb__hd__nav">
                                    <li>
                                        <Link className="nav-link active" to="#">Privacy Policy</Link>
                                    </li>
                                    <li>
                                        <Link className="nav-link" to="#">Condition of sale</Link>
                                    </li>
                                    <li>
                                        <Link className="nav-link" to="#">Conditions of Purchase</Link>
                                    </li>
                                </ul>
                                <p className="ft__copyright">Copyright © 2022 Australian Refrigeration Services  •   All rights reserved</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;